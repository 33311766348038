

.fill{
    background: linear-gradient( #FFB800 , #FB7575);
    border-radius: 20px;
}

.animationRow{
    align-items: center;
}

.button_symbol_left{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    width: 60px;
    height: 50px;
    text-align: center;
    align-items: center;
    letter-spacing: 0.1em;

    color: #000000;
    border: 0px solid #000000;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px #FFFFFF;
    border-radius: 100px;
}

.animationColumn{

    padding: 0%;
    row-gap: 0%;
}

.completeTimerRow{
    margin-top: 0%;
}

.buttonColmn{
    margin-top: 5%;
    padding: 0% !important;
    align-items: right !important;
}

.button_symbol_right{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    width: 60px;
    height: 50px;
    text-align: center;
    align-items: center;
    letter-spacing: 0.1em;

    color: #000000;
    border: 0px solid #000000;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px #FFFFFF;
    border-radius: 100px;
}


.playPauseIcon{
    width: 33px;
    height: 31px;
}

.playPauseRow{
  margin-right: 5%;
  justify-content: right;
}

.pomodoroIcon{
    margin-right: 5%;
}

.playPause{
    position: absolute;
    width: 53px;
    height: 51px;
    border: 0px solid #000000;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px #FFFFFF;
    border-radius: 100px;
}