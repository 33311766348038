.timerAnimation{
    border: 0px solid #000000;
    min-width: 100px;
    min-height: 120px;
    margin: 10%;
    border-radius: 150px;
    box-shadow: inset 8px 8px 20px rgba(0, 0, 0, 0.18);
    margin-left: 0;
    margin-right: 0;
    position: relative;
    background: linear-gradient(90deg, #FFB800 -52.16%, rgba(255, 255, 255, 0) 94.99%), #FB7575;
    margin-bottom: 2% ;
    
}


.black_car{
    position: absolute;
    margin-top: 3%;
    align-items: center;
    height: 42%;
    display: block;

}


.clockStartCount{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    color: #000000;
    position: absolute;
    bottom: 100%;
    left: -45%;
}

.clockEndCount{
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.1em;

    color: #000000;
    position: absolute;
    bottom: 100%;
    left: 45%;
}
