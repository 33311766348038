@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;700&display=swap');

.container{
  align-items: center;
  text-align: center;
  background-color: #F0F0F3;
  background: #F0F0F3;
  min-height: 100vh;
  min-width: 100vw;
}

.time{
  font-size: 40px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #000000;
  margin-top: 0%;
  padding-top: 2%;
  background-color: #F0F0F3;

}



.title{
  font-size: 80px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #000000;
  margin-top: 0%;
  padding-top: 2%;
  background-color: #F0F0F3;
}

.subtitle{

font-family: 'Rubik' , sans-serif;
font-style: normal;
font-weight: 300;
font-size: 25px;
line-height: 30px;
/* identical to box height */

text-align: center;
letter-spacing: 0.1em;

color: #000000;
}


.cameraSwitch{
  align-items: center;
  text-align: center;
  justify-content: center;
}


